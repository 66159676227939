
import { defineComponent, reactive, onMounted } from 'vue'

import BannerClassify from '@/components/common/BannerClassify.vue'
import ProductInfo from '@/components/product/ProductInfo.vue'
import FooterInfo from '@/components/common/FooterInfo.vue'
import CopyrightInfo from '@/components/common/CopyrightInfo.vue'
import MyPagination from '@/components/common/MyPagination.vue'
import { baseApiUrl } from '@/config/apiConfig'
import { getListApi, getCategorysApi, getBannerByIdApi } from '@/api/api'
import { useStore } from '@/store/index'
interface ClassifyInfo {
  code: string
  label: string
}

export default defineComponent({
  name: 'Product',
  components: {
    BannerClassify,
    ProductInfo,
    FooterInfo,
    CopyrightInfo,
    MyPagination
  },
  setup() {
    const store = useStore()
    const state = reactive<{
      bannerUrl: string
      activeCode: string
      classifyList: ClassifyInfo[]
      list: any[]
      path: any
      arr: any[]
      page: {
        current: number
        size: number
      }
      activetype: number
      total: number
    }>({
      bannerUrl: '',
      activeCode: '',
      classifyList: [
        // {
        //   code: "1",
        //   label: "全部产品",
        // },
        // {
        //   code: "2",
        //   label: "消防设备",
        // },
      ],
      list: [],
      path: '',
      arr: [],
      page: {
        current: 1,
        size: 10
      },
      activetype: 3,
      total: 0
    })

    onMounted(() => {
      state.path = baseApiUrl
      getCategorys()
      getBannerById()
    })

    const getCategorys = async () => {
      try {
        const result = await getCategorysApi()
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (data && data.length) {
          const arr = data.find(
            (item: any) => item.categoryName === '产品中心'
          ).nodes
          if (arr && arr.length) {
            state.classifyList = arr.map((item: any) => {
              return {
                code: item.categoryId.toString(),
                label: item.categoryName
              }
            })
            state.activeCode = state.classifyList[0].code
            getNews({
              articleModel: 1,
              pageNum: state.page.current,
              pageSize: state.page.size,
              categoryId: Number(state.activeCode)
            })
          }
        }
      } catch (e) {}
    }

    const changePage = (val: any) => {
      state.page.current = val.currentPage
      getNews({
        articleModel: 1,
        pageNum: state.page.current,
        pageSize: state.page.size,
        categoryId: Number(state.activeCode)
      })
    }

    const getBannerById = async () => {
      let res = await getBannerByIdApi(4)
      if (res.data.rows && res.data.rows.length) {
        state.bannerUrl = 'https://gwht.scrzhy.com' + res.data.rows[0].savePath
      }
    }

    const changeActiveCode = (activeCode: string) => {
      console.log(activeCode)
      state.activeCode = activeCode
      getNews({
        articleModel: 1,
        pageNum: state.page.current,
        pageSize: state.page.size,
        categoryId: Number(state.activeCode)
      })
    }

    const getNews = async (params: {
      articleModel: number
      pageNum?: number
      pageSize?: number
      categoryId?: number
    }) => {
      try {
        const result = await getListApi(params)
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          console.log('报错了', msg)
          return false
        }
        state.total = data.total
        state.list = data.rows.map((item: any) => {
          return {
            id: item.id,
            title: item.title,
            description: item.description
              ? item.description.substring(0, 120) + '...'
              : item.description,
            coverImage: state.path + item.coverImage,
            createTime: item.createTime
              ? item.createTime.split(' ')[0]
              : item.createTime
          }
        })

        // state.list = state.arr.slice(
        //   state.page.current * 1 - 10,
        //   state.page.current * 1 + 10
        // );
      } catch (e) {
        console.log('出错', e)
      }
    }

    return {
      state,
      changePage,
      changeActiveCode
    }
  }
})
